<template>
    <BaseLayout>
    <div class="break-16"></div>
    <section class="ol-home-category">
        
        <div class="ol-wrapper ol-wrapper--large">
            <!-- <h2 class="ol-section-title">
                <span>KATEGORI <strong>PRODUK</strong></span>
                <i></i>
            </h2> -->
            <!-- <h2 class="heading"><span>Kategori Produk</span></h2> -->
            <h2 class="ol-section-title">
                <span>Belanja Offline MORE</strong></span>
                <i></i>
            </h2>
        </div>
    </section>
    <!-- <div class="row">
        <div class="col-md-12 col-lg-6">

        </div>
        <div class="col-md-12 col-lg-6">
            <section class="ol-home-category">
        
        <div class="ol-wrapper ol-wrapper--large">
            
            <h2 >MORE</h2>
            <p>
                Jl. Mayjen HR. Muhammad No.33, Pradahkalikendal, Kec. Dukuhpakis, Surabaya, Jawa Timur 60226<br>
                <b>Senin - Jumat : </b><br>
                <b>Sabtu - Minggu : </b><br>
            </p>
            <div class="break-16"></div>
            <button class="ol-btn" style="margin-right: 8px;">Menuju Lokasi</button>
            <button class="ol-btn">Whatsapp</button>
        </div>
    </div> -->
    <div class="break-24"></div>
    <FlashsaleGroup id="flashsale" />
    <section class="ol-home-type-product" id="gift">
      <div class="ol-wrapper ol-wrapper--large">
        <div class="row no-gutters">
          <div class="col-6">
            <h2 class="ol-section-title">
              <span>Eksklusif MORE</span>
              <i></i>
            </h2>
          </div>
          
        </div>
        
      </div>
    </section>
    <section class="ol-home-type-product" id="gift">
      <div class="ol-wrapper ol-wrapper--large">
        <div class="row no-gutters">
          <div class="col-6">
            <h2 class="ol-section-title">
              <span>Brand We Carry</span>
              <i></i>
            </h2>
          </div>
          
        </div>
        
      </div>
    </section>
   </BaseLayout>
</template>
<script>

import "@/assets/css/bootstrap.css";
require("@/assets/js/imagesloaded.pkgd.min.js");
require("@/assets/js/masonry.pkgd.min.js");
require("@/assets/js/bootstrap.js");
import globalUrl from '@/util/globallUrl'
import BaseLayout from '@/components/landing/BaseLayout.vue';
import SkeletonLoader from '@/components/loading/skeletonLoader'
export default {
    name: 'OfflineStore',
    metaInfo() {
        return{
            link: [
                {rel: 'canonical', href: window.location.origin+this.$route.fullPath}
            ]
        }
    },
    components:{
        BaseLayout,
        SkeletonLoader
    },
    data(){
        return{
            input: {
                search: ""
            },
            loadingKey:6,
            page:1,
            per_page:9,
            loading:true,
            morePage:false,
            application:1,
            inspirasiKosong:false,
            room:1,
            data:[],
            scrollHeight:0,
            labelHeader:"",
            listFilter:[
                { id:null, name:'Pilih Semua'}
            ]
        }
    },
    updated() {
        
    },
    methods:{
    },
    async mounted(){
        
    }
    

}
</script>